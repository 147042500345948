import { default as confirmBQwxGcBbEvMeta } from "/home/dkarwot/Private/Projects/qtp/pages/confirm.vue?macro=true";
import { default as finishGykHxaGJlJMeta } from "/home/dkarwot/Private/Projects/qtp/pages/finish.vue?macro=true";
import { default as indexJmDP4Hik8UMeta } from "/home/dkarwot/Private/Projects/qtp/pages/index.vue?macro=true";
import { default as registerRDEeyV0t03Meta } from "/home/dkarwot/Private/Projects/qtp/pages/register.vue?macro=true";
import { default as registration_45questionsCvf6IQF8IoMeta } from "/home/dkarwot/Private/Projects/qtp/pages/registration-questions.vue?macro=true";
export default [
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/home/dkarwot/Private/Projects/qtp/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "finish",
    path: "/finish",
    component: () => import("/home/dkarwot/Private/Projects/qtp/pages/finish.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/dkarwot/Private/Projects/qtp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/dkarwot/Private/Projects/qtp/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "registration-questions",
    path: "/registration-questions",
    component: () => import("/home/dkarwot/Private/Projects/qtp/pages/registration-questions.vue").then(m => m.default || m)
  }
]